import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron02,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CDefinition,
  CSectTitle04,
  CTable,
} from '../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron02
        data={{
          title: {
            main: (
              <>
                食物アレルギーを
                <br className="u_sp" />
                お持ちの方へ
              </>
            ),
            sub: <></>,
          },
        }}
      ></CJumbotron02>
      <div className="u_pt50 u_mbLarge">
        <LWrap>
          <p className="u_tac_pc">
            ロイヤルパークホテル（東京・日本橋）では、食物アレルギーをお持ちのお客様にもお食事をお楽しみいただけるよう、
            <br className="u_pc" />
            一部メニューをご要望に応じて、【アレルゲン特定原材料8品目】および【アレルゲン特定原材料に準ずる20品目】を使用しない
            <br className="u_pc" />
            低アレルゲンの内容に変更させていただくなど、できる限りの対応をいたします。
          </p>
          <div className="c_inlineDefinitionUnit03 c_inlineDefinitionUnit03__col2 u_mb0 u_mt60">
            <div className="col">
              <CSectTitle04 title={'レストラン'} tag={'h2'} />
              <div className="c_inlineDefinition border_top">
                事前のご予約およびご注文の際にお伺いいたします。
              </div>
            </div>
            <div className="col">
              <CSectTitle04 title={'宴会場'} tag={'h2'} />
              <div className="c_inlineDefinition border_top">
                事前お打合せの際、宴会幹事様とお打合せさせていただきます。
              </div>
            </div>
          </div>
          <CTable
            exClass="u_mt60"
            data={[
              {
                title: 'アレルゲンについて',
                content: (
                  <>
                    <dl>
                      <dt className="u_fwb">アレルゲン特定原材料8品目</dt>
                      <dd>えび、かに、くるみ、小麦、そば、卵、乳、落花生</dd>
                      <dt className="u_fwb u_mt10">
                        アレルゲン特定原材料に準ずる20品目
                      </dt>
                      <dd>
                        魚介類:あわび、いか、いくら、さけ、さば
                        <br />
                        肉　類:牛肉、鶏肉、豚肉
                        <br />
                        果　物:オレンジ、キウイフルーツ、バナナ、もも、りんご
                        <br />
                        その他:アーモンド、カシューナッツ、マカダミアナッツ、 ごま、ゼラチン、大豆、やまいも
                      </dd>
                    </dl>
                  </>
                ),
              },
              {
                title: '食物アレルギー対応のご留意事項',
                content: (
                  <>
                    <p>
                      ご注文に際して、下記の内容をご確認いただいた上で、ご判断いただきますようお願い申しあげます。
                    </p>
                    <ol className="c_numberList u_mt10">
                      <li>
                        使用食材につきましては、製造元からの情報をもとに確認しております。
                      </li>
                      <li>
                        ご婚礼・ご宴会につきまして、当日の対応はいたしかねます。
                      </li>
                      <li>
                        他のメニューと同一の厨房で調理、また共通の洗浄機器を使用するため、調理過程において
                        <br className="u_pc" />
                        アレルゲン物質が微量に混入する可能性があります。
                      </li>
                      <li>
                        上記事由により、提供する料理は完全なアレルギー対応メニューではございません。
                        <br />
                        症状が重篤な場合はお客さまの安全のため、やむをえずお断りさせていただく場合がございますので、
                        <br className="u_pc" />
                        あらかじめご了承いただきますようお願い申しあげます。
                      </li>
                    </ol>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </div>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン＆バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
